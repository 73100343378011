import { Link, PageQueryDocument, PageQueryQuery } from '../graphql/generated'
import { isPast } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { locales, defaultLocale } from '~/i18n/config'

// export type LanguageSwitchLinks = Extract<
//   PageQueryQuery['route'],
//   { __typename: 'EntityCanonicalUrl' }
// >['languageSwitchLinks']

const supportedEntities = [
  'NodePage',
  'NodeOpenJob',
  'NodeLandingPage',
  'NodeCbzLandingPage',
  'NodeProtectedPage',
  'NodeNews',
  'NodeEvent',
  'Employee',
  'ToccoSeminar',
  'ToccoModule',
  // 'ToccoIntroModule',
  'ToccoCourse',
  'ToccoWebinar',
]

const shouldDisplayPage = (route: PageQueryQuery['route']) => {
  if (!route) return false
  if (route.__typename !== 'EntityCanonicalUrl') return false
  if (!route.entity) return false
  if (!route.entity.__typename) return false

  // employee is not visible if visible from is in the future
  if (route.entity.__typename === 'Employee' && route.entity.fieldVisibleFrom) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const visibleFrom = utcToZonedTime(route.entity.fieldVisibleFrom.date, tz)
    if (!isPast(visibleFrom)) return false
  }
  if (!supportedEntities.includes(route.entity.__typename)) return false

  return true
}

const maybeRedirectToCanonicalUrl = (currentPath: string, route: PageQueryQuery['route']) => {
  if (process.server) return

  const enabledLanguages = locales.filter((locale) => locale.code !== defaultLocale).map((locale) => locale.code)
  // Split route in parts by "/"
  const routePathParts = currentPath.split('/').filter(Boolean)

  // Remove first element (language prefix)
  if (enabledLanguages.includes(routePathParts[0])) {
    routePathParts.shift()
  }
  const routePathNoPrefix = '/' + routePathParts.join('/')
  if (
    routePathNoPrefix !== '/' &&
    route?.__typename === 'EntityCanonicalUrl' &&
    route.pathAlias &&
    route.pathAlias !== routePathNoPrefix
  ) {
    navigateTo(route.path)
  }
}

const getLayoutName = (route: PageQueryQuery['route']) => {
  if (!route) return 'default'
  if (route.__typename !== 'EntityCanonicalUrl') return 'default'
  if (route.entity?.__typename === 'NodeCbzLandingPage') return 'cbz' // the name of the layout file
  if (route.entity?.__typename === 'NodeProtectedPage') return 'protected' // the name of the layout file

  return 'default'
}

const getData = async (query: any, path: string) => {
  const cacheKey = `page-${path}${process.server && '-server'}`

  const response = await useAsyncData(cacheKey, async () => {
    const { data } = await query({
      query: PageQueryDocument,
      variables: { path },
    })
    return data
  })

  return {
    data: computed(() => response.data.value || undefined),
  }
}

export const useRouteLayout = async (path = '/') => {
  const { clients } = useApollo()

  const { data } = await getData(clients!.default.query, path)

  const layout = getLayoutName(data.value?.route)

  return { layout }
}

export const useRouteData = async (path = '/') => {
  const { clients } = useApollo()

  const { data } = await getData(clients!.default.query, path)

  // Redirect to target path if redirect is set
  if (data.value.route && data.value.route.__typename === 'RedirectUrl') {
    if (data.value.route.target && data.value.route.target.path) {
      navigateTo(data.value.route.target.path)
    }
  }

  // Throw 404 if page is not found
  if (!shouldDisplayPage(data?.value?.route)) {
    throw createError({ statusCode: 404, statusMessage: 'Page Not Found', fatal: true })
  }

  maybeRedirectToCanonicalUrl(path, data.value?.route)

  const { setDrupal } = useLanguageSwitcherStore()

  const links =
    data.value?.route?.__typename === 'EntityCanonicalUrl'
      ? [...(data.value.route.languageSwitchLinks || [])]
          .map((link) => {
            if (!link) return null
            return {
              locale: link.language?.id || '',
              path: link.url?.path || '',
            }
          })
          .filter(Boolean)
      : []
  setDrupal(links)

  const breadcrumbs =
    (data.value?.route?.__typename === 'EntityCanonicalUrl' &&
      data.value?.route?.breadcrumb?.filter(Boolean).map((item: Link) => {
        return {
          text: item.text || '',
          url: item.url?.path || '',
        }
      })) ||
    []

  return {
    entity: data.value?.route?.__typename === 'EntityCanonicalUrl' && data.value?.route?.entity,
    breadcrumbs,
  }
}
